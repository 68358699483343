//BASE APIS
const baseAnnualReportServi = "/annual-report-servi";
const classificationServi = "/jurisdiccional-servi";
//ANNUALREPORT APIS

const updateAnualReport = (id) =>
  `${baseAnnualReportServi}/update_report?id=${id}`;
const getCounters = (query) => `${baseAnnualReportServi}/get_counters${query}`;
const createAnualReport = `${baseAnnualReportServi}/create_report`;
const getAnualReport = (id) => `${baseAnnualReportServi}/get_report?id=${id}`;
const listAnualReports = (query) =>
  `${baseAnnualReportServi}/list_reports${query}`;
const updateAnualReportFiles = `/queries-servi/file/annual_report_attach`;

const deleteAnualReportFiles = `/queries-servi/file/delete_dcf_attach`;

// Reportes
const getReports = () => `${baseAnnualReportServi}/list_ev_reports`;
const multifilterReport = (data) => `${baseAnnualReportServi}/list_ev_reports?responsable=${data.responsable}&state=${data.state}&filter_date=${data.filter_date}&entity_id=${data.entity_id}&start_date=${data.start_date}&end_date=${data.end_date}`;
const getReport = (id) => `${baseAnnualReportServi}/get_report_ev?id=${id}`;
const manageReport = (id) =>
  `${baseAnnualReportServi}/upload_ev_report?doc_id=${id}`;

const uploadFIlesReport = (id) =>
  `${baseAnnualReportServi}/upload_ev_attach?document_id=${id}`;

// Clasificación
const getClassifition = () => `${classificationServi}/right-request-classification`;

const getClassifitionBySearching = (data) => `${classificationServi}/right-request-classification?tipo_entidad=${data.tipo_entidad || ''}&producto_cod=${data.producto_cod || ''}&macro_motivo_cod=${data.macro_motivo_cod || ''}&codigo_queja=${data.codigo_queja || ''}&insta_recepcion=${data.insta_recepcion || ''}&start_date=${data.start_date || ''}&end_date=${data.end_date || ''}&tipo_id_cf=${data.tipo_id_cf || ''}&nombre_cf=${data.nombre_cf || ''}&apoderado_nombre=${data.apoderado_nombre || ''}&email_user=${data.email_user || ''}&check_habeas_data=${data.check_habeas_data || ''}&search=${data.search || ''}&company_sfc_code=${data.company_sfc_code || ''}`

const getReportClassition = (data) => `${classificationServi}/generate_class_reports?tipo_entidad=${data.tipo_entidad || ''}&producto_cod=${data.producto_cod || ''}&macro_motivo_cod=${data.macro_motivo_cod || ''}&codigo_queja=${data.codigo_queja || ''}&insta_recepcion=${data.insta_recepcion || ''}&start_date=${data.start_date || ''}&end_date=${data.end_date || ''}&tipo_id_cf=${data.tipo_id_cf || ''}&nombre_cf=${data.nombre_cf || ''}&apoderado_nombre=${data.apoderado_nombre || ''}&email_user=${data.email_user || ''}&check_habeas_data=${data.check_habeas_data || ''}&search=${data.search || ''}&company_sfc_code=${data.company_sfc_code || ''}&class_type=${data.class_type || ''}&get_file=${data.get_file || ''}`


// Habeas data.
const getHabeasData = () => `${classificationServi}/habeas-data-classification`;
const getHabeasDataBySearching = (term) => `${classificationServi}/habeas-data-classification?search=${term}`;

export default {
  updateAnualReport,
  getCounters,
  createAnualReport,
  getAnualReport,
  listAnualReports,
  updateAnualReportFiles,
  deleteAnualReportFiles,
  getReports,
  getReport,
  multifilterReport,
  manageReport,
  getClassifition,
  getClassifitionBySearching,
  uploadFIlesReport,
  getHabeasData,
  getHabeasDataBySearching,
  getReportClassition,
};
